<template>
  <div class="search_box" ref="searchBox">
    <div class="search_content row">
      <div class="field_option">
        <div class="field_content">
          <span @click="fieldChange(item)" class="field_itme hand"
                :class="{'fie_active':serveConfig.field === item.val}" v-for="item in fields"
                :key="item.label">{{item.label}}</span>
        </div>

      </div>
      <!-- 顶部按钮盒子 -->
      <div class="top_btn_box">
        <div class="filter display_flex alignItems_center flex_spaceBetween">
          <div class="img_title">
            <img class="fileter_icon" src="@/assets/img/docuInfo/searchFilter_icon.png" alt="">
            筛选条件
          </div>
          <span class=" hand" @click="onResetFilterSearch">重置筛选</span>
        </div>
        <div class="pagination">
          <span class="pagination_total" v-if="!isLoading">共 {{total}} 条</span>
          <el-pagination small background @size-change="onSizeChange"
                         @current-change="onCurrentChange" :current-page="serveConfig.pageNum"
                         :page-sizes="[10, 20]" :page-size="serveConfig.pageSize"
                         layout="sizes,  jumper" :pager-count="11"
                         :total="total > 10000? 10000 : total" hide-on-single-page>
          </el-pagination>
        </div>
        <div class="order display_flex_center">
          <span class="key fs16">排序</span>
          <el-select v-model="serveConfig.order" @change="onOrderChange" placeholder="请选择"
                     size="mini">
            <el-option v-for="item in orderList" :key="item.name" :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>

      </div>

      <div class="search_val_content display_flex">
        <!-- 内容盒子 -->
        <div class="filter_search_box flex_shrink0 scrollbar">
          <SearchFilter ref="SearchFilter" :filterConfig="filterConfig"
                        @filterConfigChange="filterConfigChange" />
        </div>
        <!-- :i="serveConfig.pageSize * (serveConfig.pageNum - 1)" -->
        <div class="search_doclist" @click="docBoxClick" v-loading="isLoading"
             element-loading-text="正在为您加载……">
          <!-- 全选框 v-show="isSelectDocList.length"-->
          <div v-show="docList.length" class="checkout_box  display_flex flex_direction"
               ref="contentContainer">
            <div class="checkAll_box flex_shrink0">
              <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange"
                           v-model="isSelectAll">全选</el-checkbox>
            </div>
            <div ref="checkAllBox" class="select_number_box display_flex">
              <i>已选: </i>
              <span class="selectNum">{{isSelectDocList.length}}</span>
              <span>/{{docList.length}}</span>
            </div>
            <div class="openExportDialog_btn hand fs14 flex_shrink0" @click="openExportDialog">
              导出引文格式
            </div>
          </div>
          <article-list v-if="!isEmpty" :isRadio="true" :honDocuList="docList" :moInput="searchVal"
                        :i="serveConfig.pageSize * ( startIndex - 1)"
                        @checkboxChange="onCheckboxChange" :isShowEmpty="false" />
          <WechatCode v-else />
          <div class="get_more_container" v-if="!isEmpty && !isLoading">
            <span class="hand more_btn" v-if="isMore" @click="getMore">加载更多</span>
            <span v-else>暂无更多</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 选中轨迹盒子  -->
    <i class="animation_box" v-if="isShowAnimationBox" ref="animationBox"
       :style="{top:config.y+ 'px', left:config.x+ 'px', transform :`scale(${config.scale})`}"></i>
    <!-- 导出excel弹框 -->
    <ExportSelectDocFormat ref="ExportSelectDocFormat" :isExportDialog.sync="isExportDialog"
                           :selectDocIdList="isSelectDocList" @emptySelectAll="emptySelectAll"
                           @exportExcelComplete="emptySelectAll" @deleteItem="deleteItem" />
  </div>
</template>
<script>
import SearchFilter from '@/components/searchFilter/index.vue'
import ExportSelectDocFormat from '@/components/exportSearchDocDialog/index.vue'
import WechatCode from '@c/wechatCode/index.vue'
import { search } from '@/api/article.js'
import { mapGetters } from 'vuex'
import { endTime } from '@/utils/index.js'

export default {
  components: {
    SearchFilter,
    ExportSelectDocFormat,
    WechatCode
  },
  data () {
    return {
      fields: [
        {
          label: '全部',
          val: ''
        }, {
          label: '标题',
          val: '标题'
        }, {
          label: '关键词',
          val: '关键词'
        }, {
          label: '作者',
          val: '作者'
        }, {
          label: '期刊',
          val: '期刊'
        }, {
          label: '摘要',
          val: '摘要'
        }, {
          label: '单位',
          val: '单位'
        }
      ],
      isMore: true,
      startIndex: 1,
      isIndeterminate: false, // 半选状态
      isSelectAll: false, // 全选框选中状态
      filterConfig: {
        time: [1920, endTime],
        category: [],
        if: [],
        docPublishType: [],
        type: [],
        sex: [],
        age: []
      },
      searchVal: '',
      total: 0,
      serveConfig: {
        category: 3,
        filter: '',
        order: null,
        field: '',
        keywords: '',
        pageSize: 10,
        pageNum: 1
      },
      orderList: [
        { id: 2, name: '发表日期' },
        { id: 0, name: '相关性' },
        { id: 1, name: 'IF值' },
        { id: 3, name: '被引用' },
        { id: 4, name: '恢复默认' }
      ],
      docList: [],
      isSelectDocList: [], // [29088841, 34993559, 5185329], // 选中的文献列表
      // 轨迹动画配置
      config: {
        x: 0,
        y: 0,
        scale: 1
      },
      isShowAnimationBox: false, // 选中轨迹盒子装态
      isExportDialog: false, // 导出弹框状态
      isEmpty: false,
      isLoading: false
    }
  },
  methods: {
    resetList (type = true) {
      this.serveConfig.pageNum = 1
      this.docList = []
      this.startIndex = 1
      document.documentElement.scrollTop = 0
      this.getSearchDocList(type)
    },
    getMore () {
      this.serveConfig.pageNum++
      this.getSearchDocList(false)
    },
    fieldChange (item) {
      if (this.serveConfig.field === item.val) return
      this.serveConfig.field = item.val
      this.resetList()
    },
    // 删除某一项
    deleteItem (docId) {
      const i = this.isSelectDocList.indexOf(docId)
      if (i !== -1) this.isSelectDocList.splice(i, 1)
      const item = this.docList.find(item => item.id === docId)
      if (item) item.radio = false
    },
    // 取消所有选中项
    emptySelectAll () {
      this.isSelectDocList = []
      this.docList.forEach(item => {
        item.radio = false
      })
      this.isIndeterminate = false
      this.isSelectAll = false
    },
    // 打开导出弹框
    openExportDialog () {
      if (!this.userId) return this.$login()
      this.isExportDialog = true
      this.$refs.ExportSelectDocFormat.openDialog()
    },
    // 获取鼠标位置
    docBoxClick (e) {
      this.config.x = e.clientX
      this.config.y = e.clientY
      this.config.scale = 1
    },
    // 选中动画
    selectAnimation () {
      this.isShowAnimationBox = true
      this.$nextTick(() => {
        const box = this.$refs.checkAllBox
        const contentContainer = this.$refs.contentContainer
        this.config.x = box.offsetLeft + contentContainer.offsetLeft + 35
        this.config.y = document.documentElement.scrollTop > 100 ? 115 : 220 - document.documentElement.scrollTop
        this.config.scale = 0.2
      })
      setTimeout(() => {
        this.isShowAnimationBox = false
      }, 300)
    },
    // 全不选
    onNone () {
      this.docList.forEach(item => {
        item.radio = false
        this.cancelselectItem(item)
      })
      this.checkAllChange()
    },
    // 全选
    onSelectAll () {
      this.docList.forEach(item => {
        if (this.isSelectDocList.length >= 100) return
        item.radio = true
        this.isSelectDocList.push(item.id)
      })
      this.isSelectDocList = [...new Set(this.isSelectDocList)]
      this.checkAllChange()
      if (this.isSelectDocList.length >= 100) this.$myMessage.warning('最多每次可选择100条数据')
    },
    // 全选盒子
    handleCheckAllChange (val) {
      if (val) {
        this.isIndeterminate = false
        this.onSelectAll()
      } else {
        this.onNone()
      }
    },
    // 取消选择某一项
    cancelselectItem (item) {
      const i = this.isSelectDocList.findIndex(selectDocId => selectDocId === item.id)
      if (i !== -1) this.isSelectDocList.splice(i, 1)
    },
    // 切换全选状态
    checkAllChange () {
      const isDocList = this.docList.filter(item => item.radio)
      if (this.isSelectDocList.length > 0) {
        this.isIndeterminate = true
        this.isSelectAll = false
        if (isDocList.length === this.docList.length) {
          this.isIndeterminate = false
          this.isSelectAll = true
        }
      } else {
        this.isIndeterminate = false
        this.isSelectAll = false
      }
    },
    // 复选框更改
    onCheckboxChange (item) {
      if (item.radio) {
        if (this.isSelectDocList.length >= 100) {
          item.radio = false
          return this.$myMessage.warning('最多每次可选择100条数据')
        }
        this.isSelectDocList.push(item.id)
        this.selectAnimation()
      } else {
        this.cancelselectItem(item)
      }
      this.checkAllChange()
    },
    // 过滤搜索条件改变
    filterConfigChange () {
      this.$nextTick(() => {
        this.resetList()
      })
    },
    onOrderChange (newVal) {
      if (newVal === 4) {
        this.serveConfig.order = null
      }
      this.resetList()
    },
    async getSearchDocList (isEmptySelectAll = true) {
      try {
        if (isEmptySelectAll) this.emptySelectAll()
        this.isLoading = true
        this.serveConfig.keywords = this.searchVal
        this.serveConfig.filter = this.$refs.SearchFilter.getSearchFilterVal()
        const data = { ...this.serveConfig }
        if (data.order === null) data.order = 0
        const res = await search(data)
        const list = res.data.map((item) => ({
          ...item,
          radio: this.isSelectDocList.findIndex(selectDocId => selectDocId === item.id) !== -1
        }))
        this.isMore = list.length === this.serveConfig.pageSize
        this.docList = [...this.docList, ...list]
        this.isEmpty = this.docList.length === 0
        this.checkAllChange()
        this.total = this.docList[0] ? this.docList[0].totalHits : 0
      } catch (error) {
        this.$message.warning('服务错误')
      } finally {
        this.$nextTick(() => {
          this.isLoading = false
        })
      }
    },
    onCurrentChange (pageNum) {
      this.startIndex = pageNum
      this.serveConfig.pageNum = pageNum
      this.docList = []
      this.getSearchDocList(false)
    },
    onSizeChange (pagesize) {
      this.serveConfig.pageSize = pagesize
      this.resetList(false)
    },
    // 重置筛选
    onResetFilterSearch () {
      this.filterConfig = this.$options.data().filterConfig
      this.$refs.SearchFilter.resetQuickTime()
      this.$nextTick(() => this.resetList())
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  mounted () {
    this.searchVal = this.$route.query.val
    this.getSearchDocList()

    // this.openExportDialog()
  },
  watch: {
    '$route.query.val' (newVla, lodVal) {
      this.searchVal = newVla
      this.filterConfig = this.$options.data().filterConfig
      this.resetList()
      this.isSelectDocList = []
    }
  }
}
</script>
<style lang="less" scoped>
.search_box {
  // position: relative;
  background-color: #f5f7fb;
  min-height: 600px;
  .search_content {
    min-height: calc(100vh - 305px);
    padding-top: 10px;
  }
  .field_option {
    width: 1200px;
    margin: 0 auto;
    padding-left: 146px;
    .field_content {
      display: grid;
      grid-template-columns: repeat(7, auto);
      align-items: center;
      justify-content: start;
      column-gap: 10px;
      width: 77.3%;
      margin: 0 auto 10px;
      font-size: 14px;
      color: #4b639f;
    }
    .field_itme {
      padding: 3px 10px;
      border-radius: 4px;
      background-color: transparent;
      border: 1px solid #4b639f;
    }
    .fie_active {
      color: #fff;
      background-color: #4b639f;
    }
  }

  .top_btn_box {
    position: sticky;
    top: 52px;
    display: grid;
    z-index: 100;
    grid-template-columns: 280px 1fr 145px;
    background-color: #f5f7fb;
    padding: 10px 0;
    .filter {
      padding: 0 10px;
      color: #4b639f;
      .img_title {
        font-weight: 700;
        .fileter_icon {
          width: 20px;
        }
      }
    }
    .pagination {
      padding-left: 20px;
      display: grid;
      grid-template-columns: 90px auto;
      align-items: center;
      .pagination_total {
        color: #606266;
      }
    }
    .order {
      .key {
        color: #4b639f;
        font-weight: 600;
      }
      .el-select {
        margin-left: 10px;
        width: 100px;
        /deep/ .el-input__inner:focus {
          border-color: #4b639f !important;
        }
      }
    }
  }
  .search_val_content {
    .filter_search_box {
      position: sticky;
      top: 104px;
      z-index: 100;
      width: 290px;
      // max-height: calc(100vh - 230px);
      max-height: calc(100vh - 150px);
      margin-right: 10px;
      margin-bottom: 20px;
      overflow: auto;
    }
    .search_doclist {
      flex: 1;
      /deep/ .literature {
        .list:first-child {
          margin-top: 0;
        }
      }
      /deep/.is_blank {
        margin-top: 0 !important;
      }
    }
  }
  .animation_box {
    position: fixed;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: #4b639f;
    z-index: 100;
    transition: all 0.3s ease-out;
    &::after {
      content: '';
      position: absolute;
      left: 6px;
      top: 2px;
      height: 9px;
      width: 5px;
      // background-color: #fff;
      border: 1px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      transition: transform 0.15s ease-in 0.05s;
      transform-origin: center;
    }
  }
}

.get_more_container {
  padding: 5px 0 10px;
  font-size: 16px;
  color: #606266;
  text-align: center;
  .more_btn {
    &:hover {
      color: #4b639f;
      font-weight: 600;
    }
  }
}

.checkout_box {
  position: sticky;
  top: 100px;
  z-index: 99;
  display: grid;
  height: 35px;
  padding: 0 15px 0;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  overflow: hidden;
  background-color: #f5f7fb;

  .checkAll_box {
    position: relative;
    top: 1px;
    width: 100%;
    // height: 35px;
    // line-height: 35px;
    text-align: center;
    padding-right: 8px;
    border-right: 1px solid #ccc;
    /deep/.el-checkbox {
      font-weight: 400;
    }
  }
  .select_number_box {
    min-width: 75px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-size: 14px;
    color: #606266;
    .selectNum {
      margin-left: 5px;
      color: #4b639f;
    }
  }
  .openExportDialog_btn {
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #4b639f;
    // font-weight: 500;
    color: #4b639f;
    &:hover {
      background-color: #4b639f;
      color: #fff;
    }
  }
}

/deep/.select_item:first-child {
  margin-top: 0;
}
</style>
